document.addEventListener("DOMContentLoaded", function() {
    
    let upscrollers = document.getElementsByClassName('upscroller');
    if (upscrollers.length == 1) {
        upscrollers[0].addEventListener('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            return false;
        });
    }
});

(function() {
    var prevScrollpos = window.pageYOffset;
    window.addEventListener('scroll', function() {
        let logoElements = document.getElementsByClassName('logo-xs')
        let visible = logoElements.length == 1 && logoElements[0].offsetWidth > 0 && logoElements[0].offsetHeight > 0;
        if (visible) {
            var fadingElements = document.getElementsByClassName('fade-on-scroll');
            Array.prototype.forEach.call(fadingElements, function(item) {
                var currentScrollPos = window.pageYOffset;
                if (prevScrollpos > currentScrollPos) {
                    item.style.top = "0";
                } else {
                    item.style.top = "-100%";
                }
                prevScrollpos = currentScrollPos;
            });
        }
    });
})();

